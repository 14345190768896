import { postFunc } from "Services/mainApiServices";
// import { NotificationManager } from "react-notifications";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/
const GET_LISTS_ITEMS_REQ = "lists/GET_LISTS_ITEMS_REQ";
const GET_LISTS_ITEMS_SCS = "lists/GET_LISTS_ITEMS_SCS";
const GET_LISTS_ITEMS_FLR = "lists/GET_LISTS_ITEMS_FLR";

const CLEAR_FROM_LISTS = "lists/CLEAR_FROM_LISTS";

/**
|--------------------------------------------------
| ACTIONS
|--------------------------------------------------
*/
export const getListsItems = lists => async dispatch => {
  dispatch({ type: GET_LISTS_ITEMS_REQ });

  const response = await postFunc("listItemsNoToken", { lists });

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_LISTS_ITEMS_SCS,
      payload: { data: response.data }
    });
  } else {
    dispatch({ type: GET_LISTS_ITEMS_FLR });
    // NotificationManager.error(response.status.description);
  }
};

export const removeFromLists = () => dispatch => {
  dispatch({ type: CLEAR_FROM_LISTS });
};

/**
|--------------------------------------------------
| REDUCERS
|--------------------------------------------------
*/
const INIT_STATE = {
  items: {
    data: [],
    loading: false
  }
};
export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_LISTS_ITEMS_REQ:
      return {
        ...state,
        items: {
          data: [],
          loading: true
        }
      };
    case GET_LISTS_ITEMS_SCS:
      return {
        ...state,
        items: {
          ...action.payload,
          loading: false
        }
      };
    case GET_LISTS_ITEMS_FLR:
      return {
        ...state,
        items: {
          ...state.items.data,
          loading: false
        }
      };

    case CLEAR_FROM_LISTS:
      return {
        ...state,
        items: {
          loading: false
        }
      };

    default:
      return state;
  }
}
