// Redux
import { combineReducers } from "redux";

// Units
import auth from "Modules/units/Auth";
import lists from "Modules/units/Lists";
import property from "Modules/units/Property";
import validation from "Modules/units/Validation";

export default combineReducers({
  auth,
  lists,
  property,
  validation
});
