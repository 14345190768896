// React
import React from "react";

// Material-UI
import Container from "@material-ui/core/Container";

// Layout components
import Header from "Layouts/sections/Header";

// BG Image
import BG from "Assets/images/mainBG.jpg";

// A2HS Tutorial
import A2HS from "Components/molecules/A2HS";

const HomeLayout = state => {
  return (
    <>
      <Header position="relative" />
      <A2HS />
      <div
        style={{
          backgroundColor: "#c2eafc",
          backgroundImage: `url(${BG})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat"
        }}
      >
        <Container
          style={{
            height: "calc(100vh - 72px)"
          }}
          fixed
          maxWidth="xl"
        >
          {state.children}
        </Container>
      </div>
    </>
  );
};

export default HomeLayout;
