// React
import React from "react";

// Material-UI
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

// Layout components
import Header from "Layouts/sections/Header";

// A2HS Tutorial
import A2HS from "Components/molecules/A2HS";

const TripleLayout = state => (
  <>
    <Header />
    <A2HS />
    <Container fixed maxWidth="xl">
      <Box mt={16} mb={6}>
        {state.children}
      </Box>
    </Container>
  </>
);

export default TripleLayout;
