// React
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Material UI
import { Box, makeStyles } from "@material-ui/core";

// Share icon
import iosShareIcon from "Assets/images/ios_share_icon.png";

const style = makeStyles(() => ({
  container: {
    backgroundColor: "#272727e0",
    color: "#fff",
    position: "fixed",
    bottom: 0,
    right: 0,
    padding: "1.7em 1.9em",
    borderRadius: "50px",
    textDecoration: "none",
    width: "100%",
    animation: "wiggle 2.5s infinite",
    marginBottom: "10px",
    textAlign: "center",
    zIndex: "999999"
  },
  icon: {
    width: "20px",
    height: "27px",
    margin: "0 5px"
  }
}));

const A2HS = () => {
  const { t } = useTranslation();
  const classes = style();
  const [showA2HS, setShowA2HS] = useState(false);

  // Detects if device is on iOS
  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  // Detects if device is in standalone mode
  const isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone;

  useEffect(() => {
    if (isIos() && !isInStandaloneMode()) {
      setShowA2HS(true);
    }
  }, []);

  return (
    <div>
      {showA2HS && (
        <Box className={classes.container} onClick={() => setShowA2HS(false)}>
          {t("To install this webapp on your device; tap")}
          <img alt="iosshareicon" src={iosShareIcon} className={classes.icon} />
          {t("and then Add to homescreen.")}
        </Box>
      )}
    </div>
  );
};

export default A2HS;
