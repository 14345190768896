import { dataURLtoFile } from "Util/helpers";

/* eslint-disable no-unused-expressions */
export const getCookie = name => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts
      .pop()
      .split(";")
      .shift();
  }
  return "en";
};

export const getCookieWithPromise = async name => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  return new Promise(resolve => {
    if (parts.length === 2) {
      resolve(
        parts
          .pop()
          .split(";")
          .shift()
      );
    } else {
      resolve("en");
    }
  });
};

export const getNameFromList = (lists, listName, id) => {
  let finalName = "";
  lists?.items?.data?.forEach(listItem => {
    if (Object.keys(listItem)[0] === listName) {
      const name = Object.values(listItem)[0].filter(item => item.id === id)[0];
      finalName = name?.text;
    }
  });
  return finalName;
};

export const convertToFile = (path, setFiles) => {
  if (path.split("/")[path.split("/").length - 1] !== "default.jpg") {
    (async function() {
      const blob = await fetch(path).then(r => r.blob());
      const dataUrl = await new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
      setFiles([dataURLtoFile(dataUrl, path, path, URL.createObjectURL(blob))]);
    })();
  }
};
