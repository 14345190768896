module.exports = {
  // HEADER
  Register: "Register property",
  "Already have an account": "Have an account?",
  "Just type in the email you registered to tripleHost and we will send you your login information.":
    "Just type in the email you registered to tripleHost and we will send you your login information.",

  // ACTIONS
  Back: "Back",
  Next: "Next",
  Finish: "Finish",
  Resend: "Resend",
  Create: "Create",
  Remove: "Remove",
  Search: "Search",
  Confirm: "Confirm",
  "Add new": "Add new",
  Save: "Save",

  // INPUTS
  "E-mail address": "E-mail address",
  "Check in": "Check in",
  "Check out": "Check out",
  "No. of guests": "No. of guests",
  "First name": "First name",
  "Last name": "Last name",
  Email: "Email",

  // DROPZONE
  drop_here_label: "drag & drop or click to upload",
  "Remove image by clicking on it or": "Remove image by clicking on it or",
  "Remove All": "Remove All",

  // AUTOCOMPLETE
  "Where to": "Where to?",
  "No options": "No options",

  // REGISTRATION
  "Create an account to list and manage your property.":
    "Create an account to list, manage and promote your property.",
  "I agree that data can be used by tripleHost staff to contact me regarding tripleHost business offers and support.":
    "By clicking CREATE I agree that data can be used by tripleHost staff to contact me regarding tripleHost business offers and support.",
  "Portal listing": "Portal listing",
  "Promote your property": "Promote your property",
  "Admin panel": "Admin panel",
  "Manage your bussines": "Manage your bussines",
  "Guest app": "Guest app",
  "Engage your guests": "Engage your guests",
  "Confirm your email address": "Confirm your email address",
  check_link:
    "We have sent an email with a confirmation link to your email address. In order to complete the sign-up process and continue to customization, please click the confirmation link.",

  // CUSTOMIZATION
  "APP CUSTOMIZATION": "APP CUSTOMIZATION",
  "PROPERTY INFO": "PROPERTY INFO",
  ACCOMODATION: "ACCOMODATION",
  "Basic information": "Basic information",
  Name: "Name",
  "Property name": "Property name",
  "Property rating (if it has one)": "Property rating (if it has one)",
  "Property type": "Property type",
  Country: "Country",
  step_one_info_1:
    "Why is this step important? The answer is very simple - you are creating an application to digitize your object. Do you have a hotel? Call the app by your hotel. Do you have a hotel chain and want to have a unique app for all hotels? You are in a good place, call your application by the name of your group and add your first hotel in the next step.",
  step_one_info_menu_1:
    "Create an application to digitize your offer. Do you have a restaurant, bar or tavern? Name it after your property.",
  step_one_info_2:
    "Why pick the country? The state is here to simplify the entry of parameters such as time zone, currency and language to be used on the application. Of course, in the advanced settings you can change everything individually and add an unlimited number of languages.",
  step_one_info_3:
    "To make the application attractive on your guests devices, make sure that the logo and icon are well sized and choose the colors that will be displayed in the application. The recommended resolution of the logo is 320 x 70 pixels. The icon should be in the 1x1 ratio. If you have special wishes for branding the application, we are at your disposal at info@triple-host.com",
  "Property images": "Property images",
  Location: "Location",
  Amenities: "Amenities",
  "Set as featured": "Set as featured",
  "Check in from": "Check in from",
  "Check in until": "Check in until",
  "Check out from": "Check out from",
  "Check out until": "Check out until",
  Rules: "Rules",
  property_info_1:
    "First, choose your property type and then import your best images to fully present your property. We reccomend images taken with wider lense to capture more of your property in single image. Images should be compressed and .jpg formatted.",
  property_info_2:
    "Pin your exact location to help guests find you faster. Search your location and with a click on map you will drop a pin on your desired spot. Also pick amenities that are avalible and set rules for your property. You will also be able to change this properties later.",
  Logo: "Logo",
  "Logo (negative/white)": "Logo (negative)",
  Icon: "Icon",
  "Primary color": "Primary color",
  "Secondary color": "Secondary color",
  "Accommodation types": "Accommodation types",
  Images: "Images",
  "Size (sqm)": "Size (sqm)",
  Capacity: "Capacity",
  "Number of this type": "Number of this type",
  customization_info_1:
    "To add new housing unit please click ADD NEW button. You can add it later if you don’t want do it right now.    ",
  customization_info_2:
    "Click FINISH to review your property informations and complete your registration.",
  "COMING SOON": "COMING SOON",
  "App name": "App name",
  "Property name and type": "Property name and type",
  "Accomodation types": "Accomodation types",
  "Review and complete": "Review and complete",
  review_desc:
    "Please check your information, if it is correct click on accept to get login information.",
  "Save acc. type": "Save acc. type",

  // REGISTRATION
  "Congrats!": "Congrats!",
  "Your settup is completed": "Your settup is completed",
  "Go to the admin panel": "Go to the admin panel",
  "Check out the guest app": "Check out the guest app",
  "View your property on portal": "View your property on portal",
  "Activating account": "Activating account... Please wait.",
  "Resend token text":
    "This activation token is expired. You can request another one if you have not completed the registration. Just type in your e-mail address.",
  "Resend info text":
    "It seems like you have completed the registration. If you have forgotten your access data, we can send it back to your e-mail.",
  "Checking your activation state":
    "Checking your activation state... Please wait",

  // SELECT VIEW
  "Places found": "Places found",
  "No properties in this place. Please try again with different place.":
    "No properties in this place. Please try again with different place.",

  // SINGLE VIEW
  "Check avalible rooms": "Check avalible rooms",
  "Book selected rooms": "Book selected rooms",
  "Book this many rooms": "Book this many rooms",
  "I want to book": "I want to book:",
  Total: "Total: ",
  "Congratulations! You have succesfully placed your reservation.":
    "Congratulations! You have succesfully placed your reservation.",
  "Check your email for further instructions.":
    "Check your email for further instructions.",
  Reviews: "Reviews",
  "No reviews": "There are no reviews for this property yet",
  "Not verified": "Not verified",
  "Verification info":
    "Verification of the property usually takes 10 minutes, and if you have problems, contact us at info@triple-host.com",

  // A2HS
  "To install this webapp on your device; tap":
    "To install this webapp on your device; tap",
  "and then Add to homescreen.": "and then Add to homescreen.",

  // MENU
  "Digital menu information": "Digital menu information",
  RegisterMenu: "Register",
  "Create digital menu for your property":
    "Create digital menu for your property",
  menuSignupTitle: "1. Sign up",
  menuSignupText:
    "Enter your basic information such as name of your property, logo and colors to best represent your brand.",
  menuEnterTitle: "2. Enter your products",
  menuEnterText:
    "Enter products and services you offer. Add pictures, price and description.",
  menuReadyTitle: "3. Digital menu is ready",
  menuTextTitle:
    "Your digital menu is ready. You can start offering it to your guests.",

  // MENU PAYMENT
  PayTitle: "Payment details",
  PaySubtitle:
    "To finish your registration please select payment package and add payment details",
  "I agree to terms": "I agree to terms",
  tosCheckText1: "By confirming the box below, you agree to our",
  tosCheckText2: "terms of use.",
  tosCheckText3:
    " TripleHost will automatically renew your membership and charge a monthly fee for it (currently ",
  tosCheckText4:
    ") via your chosen payment method until you cancel the service. You can cancel the service at any time to avoid future charges.",
  WSInfo:
    "TripleS d. o. o. uses WSPay for online payments. WSPay is a secure system for online payments, real time credit and debit card payments, and other payment methods. WSPay ensures the buyer and the merchant with the secure card data entry and transfer, which is also confirmed by PCI DSS certificate. WSPay uses 256-bit SSL encryption and TLS 1.2 cryptographic protocol as the highest protection standards for data entry and transfer.",
  "ONLINE CARD PAYMENT": "ONLINE CARD PAYMENT",

  // BACKEND STRINGS
  "Time is not valid": "Time is not valid",
  "You must provide Tenant": "You must provide Tenant",
  "Admin doesn't exists": "Admin doesn't exists",
  "Gallery item doesn't exist": "Gallery item doesn't exist",
  "List item doesn't exist": "List item doesn't exist",
  "Data is not valid": "Data is not valid",
  "Date is in past": "Date is in past",
  "Scheduled date is in past": "Scheduled date is in past",
  "Email didn't send because it's empty":
    "Email didn't send because it's empty",
  "You must provide organisation": "You must provide organisation",
  "You must provide calendar": "You must provide calendar",
  "Service must be of type calendar": "Service must be of type calendar",
  "This date is already added": "This date is already added",
  "This calendar is already taken": "This calendar is already taken",
  "This calendar is not working today": "This calendar is not working today",
  "This date has reservations": "This date has reservations",
  "You must provide working day": "You must provide working day",
  "Working day already exists for this organisation":
    "Working day already exists for this organisation",
  'User organisations doesn"t exist': 'User organisations doesn"t exist',
  'Working hours doesn"t exist': 'Working hours doesn"t exist',
  "Organisation did not start working": "Organisation did not start working",
  "Working hours of the organization are over":
    "Working hours of the organization are over",
  'User doesn"t exist': 'User doesn"t exist',
  'Housing type doesn"t exist': 'Housing type doesn"t exist',
  'Housing type amenity doesn"t exist': 'Housing type amenity doesn"t exist',
  "Room is not active": "Room is not active",
  "Credit card doesn't exist": "Credit card doesn't exist",
  "More than 7 days passed since your reservation expired":
    "More than 7 days passed since your reservation expired",
  "Review doesn't exist": "Review doesn't exist",
  "Review type doesn't exist": "Review type doesn't exist",
  "Review already exists": "Review already exists",
  "Credit card already exists": "Credit card already exists",
  "You have to deactivate corresponding number of rooms first":
    "You have to deactivate corresponding number of rooms first",
  "Campaign doesn't exist": "Campaign doesn't exist",
  "Review type is not valid": "Review type is not valid",
  "You have exceeded total number of rooms for this billing plan":
    "You have exceeded total number of rooms for this billing plan",
  "The billing plan has expired": "The billing plan has expired",
  "Transaction is not valid": "Transaction is not valid",
  "Billing doesn't exist": "Billing doesn't exist",
  "Token is invalid": "Token is invalid",
  "Description length over 100 characters":
    "Description length over 100 characters",
  "Subscription doesn't exist": "Subscription doesn't exist",
  "This Tenant already exist": "This Tenant already exist",
  "Property type is not valid": "Property type is not valid",
  "Country is not valid": "Country is not valid",
  "Currency is not valid": "Currency is not valid",
  "Language is not valid": "Language is not valid",
  "Duration is not valid": "Duration is not valid",
  "Plan is not valid": "Plan is not valid",
  "This email already exist": "This email already exist",
  "This name already exist": "This name already exist",
  "Invalid step": "Invalid step",
  "You have unfinished reservations for this calendar":
    "You have unfinished reservations for this calendar",
  "This title already exist": "This title already exist",
  "This room number already exist": "This room number already exist",
  "This domain already exist": "This domain already exist",
  "This admin domain already exist": "This admin domain already exist",
  "Not a valid subscription": "Not a valid subscription",
  "Order doesn't exist": "Order doesn't exist",
  "Order item doesn't exist": "Order item doesn't exist",
  "Unavailable item/s. Please remove it from the cart":
    "Unavailable item/s. Please remove it from the cart",
  "Unavailable item": "Unavailable item",
  "Service doesn't exist": "Service doesn't exist",
  "Service is not activated": "Service is not activated",
  "Service is not available": "Service is not available",
  "Message is over 250 characters": "Message is over 250 characters",
  "Filename is empty": "Filename is empty",
  "Filename format is not allowed": "Filename format is not allowed",
  "Image is not provided": "Image is not provided",
  "Visibility doesn't exist": "Visibility doesn't exist",
  "Reservation type doesn't exist": "Reservation type doesn't exist",
  "Reservation doesn't exist": "Reservation doesn't exist",
  "3DS Transaction Redirect": "3DS Transaction Redirect",
  "Reservation is not active": "Reservation is not active",
  "Tenant is not activated": "Tenant is not activated",
  "Tenant is already activated": "Tenant is already activated",
  "Organisation is already activated": "Organisation is already activated",
  "Tenant is already verified": "Tenant is already verified",
  "Tenant is no verified": "Tenant is no verified",
  "Tenant is already registered": "Tenant is already registered",
  "Extras category doesn't exist": "Extras category doesn't exist",
  "Extras category doesn't activated": "Extras category doesn't activated",
  "Gallery doesn't exist": "Gallery doesn't exist",
  "Price can't be negative": "Price can't be negative",
  "Date from is greater than date to": "Date from is greater than date to",
  "You have not entered a date until": "You have not entered a date until",
  "Date to must be greater than todays date":
    "Date to must be greater than todays date",
  "Opening time is greater than closing time":
    "Opening time is greater than closing time",
  "Working time from is greater than working time to":
    "Working time from is greater than working time to",
  "Working day is not in range of 0 - 6":
    "Working day is not in range of 0 - 6",
  "Guest already exists": "Guest with given identity already exists",
  "Room doesn't exist": "Room doesn't exist",
  "Room already exists": "Room with given number already exists",
  "Room is already taken": "Room is already taken",
  "Guest doesn't exist": "Guest doesn't exist",
  "Guest with given reservation doesn't exist":
    "Guest with given reservation doesn't exist",
  "Something went wrong": "Something went wrong",
  "No panel connection": "No panel connection",
  "Access denied": "Access denied",
  "You can't change limit": "You can't change limit because you are not holder",
  "You have successfully accessed the route":
    "You have successfully accessed the route",
  "Successfully inserted": "Successfully inserted",
  "Passwords do not match": "Passwords do not match",
  "Payment succesfuly aproved. Check your mail for invoice":
    "Payment succesfuly aproved. Check your mail for invoice",
  "Successfully updated": "Successfully updated",
  "Successfully processed": "Successfully processed",
  "Connection refuse": "Connection refuse",
  "This reservation has not started yet":
    "This reservation has not started yet",
  "This reservation has expired": "This reservation has expired",
  "Please select at least one role": "Please select at least one role",
  "Please select at least one organisation":
    "Please select at least one organisation",
  "Please select at least one date": "Please select at least one date",
  "Please select at least one language": "Please select at least one language",
  "Please select at least one accommodation type":
    "Please select at least one accommodation type",
  "Not a valid boolean": "Not a valid boolean",
  "This featured already exist": "This featured already exist",
  "This tag already exist": "This tag already exist",
  "This tagging already exist": "This tagging already exist",
  "This tag does not exist": "This tag does not exist",
  "Notification status doesn't exist": "Notification status doesn't exist",
  "Notification doesn't exist": "Notification doesn't exist",
  "This tagging does not exist": "This tagging does not exist",
  "This organisation does not exist": "This organisation does not exist",
  "Organisation type doesn't exist": "Organisation type doesn't exist",
  "Organisation type is not active": "Organisation type is not active",
  "This accommodation type does not exist":
    "This accommodation type does not exist",
  "This amenity does not exist": "This amenity does not exist",
  "Organisation is not activated": "Organisation is not activated",
  "Organisation is not available": "Organisation is not available",
  "Category does not exist": "Category does not exist",
  "Category is not active": "Category is not active",
  "Sub category does not exist": "Sub category does not exist",
  "Sub category is not active": "Sub category is not active",
  "Accomodation type does not exist": "Accomodation type does not exist",
  "Successfully deleted": "Successfully deleted",
  "Language already exist": "Language already exist",
  "Language doesn't exist": "Language doesn't exist",
  "Accommodation type is not activated": "Accommodation type is not activated",
  "Accommodation unit doesn't exist": "Accommodation unit doesn't exist",
  "Accommodation unit is not activated": "Accommodation unit is not activated",
  "Home screen row doesn't exist": "Home screen row doesn't exist",
  "Home screen row is not activated": "Home screen row is not activated",
  "No price list is ined for this type": "No price list is ined for this type",
  "No price is specified for one or more selected accommodation types in this time interval":
    "No price is specified for one or more selected accommodation types in this time interval",
  "For this type of accommodation in the specified time interval there are one or more days with an unined price":
    "For this type of accommodation in the specified time interval there are one or more days with an unined price",
  "Accommodation has already been assigned":
    "Accommodation has already been assigned",
  "Accommodation has not been assigned, or accommodation type for this reservation does not exist":
    "Accommodation has not been assigned, or accommodation type for this reservation does not exist",
  "For this reservation accommodation type does not exist":
    "For this reservation accommodation type does not exist",
  "You are not placed in a room": "You are not placed in a room",
  "This guest is not assigned": "This guest is not assigned",
  "This accommodation unit is not available at this time":
    "This accommodation unit is not available at this time",
  "Accommodation unit type does not match the booked type":
    "Accommodation unit type does not match the booked type",
  "The accommodation cannot be updated because it is a reservation from the portal":
    "The accommodation cannot be updated because it is a reservation from the portal",
  "You are no longer a guest, please contact the administrator":
    "You are no longer a guest, please contact the administrator",
  "Guest does not exist, please contact the administrator":
    "Guest does not exist, please contact the administrator",
  "Incorrect room key": "Incorrect room key",
  "Type of accommodation no accommodation units created":
    "Type of accommodation no accommodation units created",
  "Type of accommodation there are no available accommodation units":
    "Type of accommodation there are no available accommodation units",
  "This accommodation type does not exist, or there are no available  accommodation units":
    "This accommodation type does not exist, or there are no available accommodation units",
  "Accommodation type does not have enough accommodation units available":
    "Accommodation type does not have enough accommodation units available",
  "You must enter the limit amount": "You must enter the limit amount",
  "You have exceeded the limit": "You have exceeded the limit",
  "The order is already in this state": "The order is already in this state",
  "You cannot manage a finished order": "You cannot manage a finished order",
  "One or more side dishes cannot be ordered":
    "One or more side dishes cannot be ordered",
  "You have not selected a date from": "You have not selected a date from",
  "You have not selected a scheduled time":
    "You have not selected a scheduled time",
  "You have not selected a date from or date to":
    "You have not selected a date from or date to",
  "This template does not exists": "This template does not exists",
  "Field is required": "Field is required",
  "Price can not be negative": "Price can not be negative",
  "Value can not be negative": "Value can not be negative",
  "Field must be between 2 and 100 characters long":
    "Field must be between 2 and 100 characters long",
  "Field must be between 1 and 100 characters long":
    "Field must be between 1 and 100 characters long",
  "Field must be between 6 and 128 characters long":
    "Field must be between 6 and 128 characters long",
  "Number must be in range of 1-20": "Number must be in range of 1-20",
  "Value must be greater than 0": "Value must be greater than 0",
  "Email is not in the correct format": "Email is not in the correct format",
  "Email is to long": "Email is to long",
  "Stars must be in range of 1-5": "Stars must be in range of 1-5",
  "Amount can not be negative": "Amount can not be negative"
};
