/* eslint-disable no-unused-expressions */
// eslint-disable-next-line consistent-return
import { useState, useEffect } from "react";

export function formatPrice(price) {
  const formatter = new Intl.NumberFormat("en", {
    currency: "EUR",
    style: "currency",
    notation: "compact",
    minimumFractionDigits: 2
  });

  const formatedPrice = formatter.format(price);

  return formatedPrice;
}

export function usePersistedState(key, defaultValue) {
  const [state, setState] = useState(
    () => JSON.parse(localStorage.getItem(key)) || defaultValue
  );
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
}

export const dataURLtoFile = (dataurl, filename, id, preview) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  const file = new File([u8arr], filename, { type: mime });
  file.id = id;
  file.preview = preview;
  return file;
};

export const getRadius = bounds => {
  // r = radius of the earth in km
  const r = 6378.8;
  // degrees to radians (divide by 57.2958)
  const neLat = bounds?.getNorthEast().lat() / 57.2958;
  const neLng = bounds?.getNorthEast().lng() / 57.2958;
  const cLat = bounds?.getCenter().lat() / 57.2958;
  const cLng = bounds?.getCenter().lng() / 57.2958;
  // distance = circle radius from center to Northeast corner of bounds
  const rKm =
    r *
    Math.acos(
      Math.sin(cLat) * Math.sin(neLat) +
        Math.cos(cLat) * Math.cos(neLat) * Math.cos(neLng - cLng)
    );

  return rKm * 1000;
};

export const getNameFromList = (lists, listName, id) => {
  let finalName = "";
  lists?.items?.data?.forEach(listItem => {
    if (Object.keys(listItem)[0] === listName) {
      const name = Object.values(listItem)[0].filter(item => item.id === id)[0];
      finalName = name?.text;
    }
  });
  return finalName;
};
