// React, Redux
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Popover } from "@material-ui/core";

// Atoms
import IconButton from "Components/atoms/buttons/IconButton";

// Molecules
import ListItem from "Components/molecules/ListItem";

// Utils
import moment from "moment";
import { getCookie, getCookieWithPromise } from "Util/functions";

const ChangeLanguage = props => {
  const { color } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    getCookieWithPromise("lang").then(response => {
      i18n.changeLanguage(response);
      moment.locale(response);
    });
    return () => {};
  }, []);

  const changeLanguage = value => {
    i18n.changeLanguage(value);
    moment.locale(value);
    document.cookie = `lang=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`;
    setAnchorEl(null);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton icon="language" color={color} onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {[
          { id: "en", text: "en", description: "English" },
          { id: "hr", text: "hr", description: "Hrvatski" },
          { id: "de", text: "de", description: "Deutsch" }
        ].map(item => (
          <ListItem
            key={item.id}
            primaryLabel={`${item.description} (${item.text})`}
            selected={getCookie("lang") === item.text}
            clickable
            onClick={() => changeLanguage(item.text)}
          />
        ))}
      </Popover>
    </>
  );
};

ChangeLanguage.defaultProps = {
  color: "primary"
};

export default ChangeLanguage;
