// React
import { lazy } from "react";

// Routes (Non splitted)
import HomeLayout from "Layouts/HomeLayout";
// import BaseLayout from "Layouts/BaseLayout";
import TripleLayout from "Layouts/TripleLayout";
import TripleLayoutMenu from "Layouts/TripleLayoutMenu";
import SelectLayout from "Layouts/SelectLayout";

// Routes (Code splitting)
const Home = lazy(() => import("Pages/home/index.js"));
const Register = lazy(() => import("Pages/register/index.js"));
const RegisterMenu = lazy(() => import("Pages/register-menu/index.js"));
const HaveAccount = lazy(() => import("Pages/register/have-account.js"));
const HaveAccountMenu = lazy(() =>
  import("Pages/register-menu/have-account.js")
);
const Activate = lazy(() => import("Pages/register/activate.js"));
const ActivateMenu = lazy(() => import("Pages/register-menu/activate.js"));
const AlreadyActivated = lazy(() =>
  import("Pages/register/already-activated.js")
);
const AlreadyActivatedMenu = lazy(() =>
  import("Pages/register-menu/already-activated.js")
);
const Customization = lazy(() => import("Pages/customization/index.js"));
const CustomizationMenu = lazy(() =>
  import("Pages/customization-menu/index.js")
);
const PaymentResult = lazy(() =>
  import("Pages/customization-menu/payment-result.js")
);
const SelectView = lazy(() => import("Pages/select-view/index.js"));
const SingleView = lazy(() => import("Pages/single-view/index.js"));

/**
|--------------------------------------------------
| PUBLIC ROUTES
|--------------------------------------------------
*/

export const publicRoutes = [
  {
    id: "home",
    title: "Homepage",
    description: "Where to? Find hotels and camps that suits you best.",
    path: "",
    exact: true,
    component: Home,
    layout: HomeLayout
  },
  {
    id: "register",
    title: "Register property",
    description: "Register your property",
    path: "register",
    component: Register,
    layout: TripleLayout
  },
  {
    id: "register-menu",
    title: "Register digital menu",
    description: "Register digital menu",
    path: "register-menu",
    component: RegisterMenu,
    layout: TripleLayoutMenu
  },
  {
    id: "haveAcc",
    title: "Already have an account?",
    description: "Resend login information",
    path: "haveAccount",
    component: HaveAccount,
    layout: TripleLayout
  },
  {
    id: "haveAccMenu",
    title: "Already have an account?",
    description: "Resend login information (menu)",
    path: "haveAccount-menu",
    component: HaveAccountMenu,
    layout: TripleLayout
  },
  {
    id: "activateEmail",
    title: "Email activation",
    description: "Activate your email",
    path: "activate",
    component: Activate,
    layout: TripleLayout
  },
  {
    id: "activateEmailMenu",
    title: "Email activation for menu",
    description: "Activate your email (menu)",
    path: "activate-meni",
    component: ActivateMenu,
    layout: TripleLayoutMenu
  },
  {
    id: "activateEmail",
    title: "Alrady activated",
    description: "Alrady activated",
    path: "already-activated",
    component: AlreadyActivated,
    layout: TripleLayout
  },
  {
    id: "activateEmailMeni",
    title: "Alrady activated (menu)",
    description: "Alrady activated",
    path: "already-activated-meni",
    component: AlreadyActivatedMenu,
    layout: TripleLayoutMenu
  },
  {
    id: "customization",
    title: "Finishing touches",
    description: "Complete your registration",
    path: "customization",
    component: Customization,
    layout: TripleLayout
  },
  {
    id: "customization-menu",
    title: "Customize menu",
    description: "Complete your registration",
    path: "customization-menu",
    component: CustomizationMenu,
    layout: TripleLayoutMenu
  },
  {
    id: "payment-result",
    title: "Payment result",
    description: "Payment result",
    path: "payment-result",
    component: PaymentResult,
    layout: TripleLayoutMenu
  },
  {
    id: "select",
    title: "Search",
    description: "Search accommodations",
    path: "select",
    component: SelectView,
    layout: SelectLayout
  },
  {
    id: "single",
    title: "Property details",
    description: "View property details",
    path: "single",
    component: SingleView,
    layout: TripleLayout
  }
];

/**
|--------------------------------------------------
| PRIVATE ROUTES
|--------------------------------------------------
*/

export const privateRoutes = [];
