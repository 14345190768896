/* eslint-disable camelcase */
import { getFunc, postFunc } from "Services/mainApiServices";
import { NotificationManager } from "react-notifications";
import { getCookie } from "Util/functions";

/**
|--------------------------------------------------
| TYPES
|--------------------------------------------------
*/
const GET_ALL_TENANTS_REQ = "tenants/GET_ALL_TENANTS_REQ";
const GET_ALL_TENANTS_SCS = "tenants/GET_ALL_TENANTS_SCS";
const GET_ALL_TENANTS_FLR = "tenants/GET_ALL_TENANTS_FLR";

const GET_TENANT_FOR_SINGLE_VIEW_REQ = "tenants/GET_TENANT_FOR_SINGLE_VIEW_REQ";
const GET_TENANT_FOR_SINGLE_VIEW_SCS = "tenants/GET_TENANT_FOR_SINGLE_VIEW_SCS";
const GET_TENANT_FOR_SINGLE_VIEW_FLR = "tenants/GET_TENANT_FOR_SINGLE_VIEW_FLR";

const GET_ACC_TYPES_REQ = "property/GET_ACC_TYPES_REQ";
const GET_ACC_TYPES_SCS = "property/GET_ACC_TYPES_SCS";
const GET_ACC_TYPES_FLR = "property/GET_ACC_TYPES_FLR";

const GET_AMENITIES_REQ = "property/GET_AMENITIES_REQ";
const GET_AMENITIES_SCS = "property/GET_AMENITIES_SCS";
const GET_AMENITIES_FLR = "property/GET_AMENITIES_FLR";

const GET_RULES_REQ = "property/GET_RULES_REQ";
const GET_RULES_SCS = "property/GET_RULES_SCS";
const GET_RULES_FLR = "property/GET_RULES_FLR";

const GET_REVIEWS_REQ = "property/GET_REVIEWS_REQ";
const GET_REVIEWS_SCS = "property/GET_REVIEWS_SCS";
const GET_REVIEWS_FLR = "property/GET_REVIEWS_FLR";
const CLEAR_REVIEWS = "property/CLEAR_REVIEWS";

/**
|--------------------------------------------------
| ACTIONS
|--------------------------------------------------
*/
export const getAllTenants = (
  lat,
  lng,
  rad,
  cin,
  cout,
  guests,
  filter,
  start,
  length
) => async dispatch => {
  dispatch({ type: GET_ALL_TENANTS_REQ });

  const body = {
    filter_data: {
      lat,
      long: lng,
      radius: Math.ceil(rad),
      date_of_accommodation: {
        check_in: cin,
        check_out: cout
      },
      number_of_guests: guests
    },
    pagination_data: { start, length }
  };

  const response = await postFunc(`organisations/getAllInRadius`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ALL_TENANTS_SCS,
      payload: { data: response.data, total: response.total }
    });
  } else {
    dispatch({ type: GET_ALL_TENANTS_FLR });
    // NotificationManager.error(response?.status?.description);
  }
};

export const getTenantForSingleView = (
  id,
  cin,
  cout,
  auth
) => async dispatch => {
  dispatch({ type: GET_TENANT_FOR_SINGLE_VIEW_REQ });

  const body = {
    org_core_id: id,
    check_in: cin,
    check_out: cout,
    lang: getCookie("lang", auth)
  };

  const response = await postFunc(`organisations/singleViewShowPrices`, body);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_TENANT_FOR_SINGLE_VIEW_SCS,
      payload: { data: response.data, total: response.total }
    });
  } else {
    dispatch({ type: GET_TENANT_FOR_SINGLE_VIEW_FLR });
    // NotificationManager.error(response?.status?.description);
  }
};

export const getAccTypes = (type, lang) => async dispatch => {
  dispatch({ type: GET_ACC_TYPES_REQ });

  const response = await getFunc(`organisationTypes/${lang}/${type}`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_ACC_TYPES_SCS,
      payload: { data: response.data }
    });
  } else {
    dispatch({ type: GET_ACC_TYPES_FLR });
    // NotificationManager.error(response?.status?.description);
  }
};

export const getAmenities = () => async dispatch => {
  dispatch({ type: GET_AMENITIES_REQ });

  const response = await getFunc(`amenitiesOrganisations`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_AMENITIES_SCS,
      payload: { data: response.data }
    });
  } else {
    dispatch({ type: GET_AMENITIES_FLR });
    // NotificationManager.error(response?.status?.description);
  }
};

export const getRules = () => async dispatch => {
  dispatch({ type: GET_RULES_REQ });

  const response = await getFunc(`amenitiesOrganisationRules`);

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_RULES_SCS,
      payload: { data: response.data }
    });
  } else {
    dispatch({ type: GET_RULES_FLR });
    // NotificationManager.error(response?.status?.description);
  }
};

export const getReviews = (orgId, auth, start, length) => async dispatch => {
  dispatch({ type: GET_REVIEWS_REQ });

  const body = {
    pagination_data: {
      start,
      length
    }
  };

  const response = await postFunc(
    `stayReviews/paginationByOrganisation/${getCookie("lang", auth)}/${orgId}`,
    body
  );

  if (response.status.errorCode === 200) {
    dispatch({
      type: GET_REVIEWS_SCS,
      payload: { data: response.data, total: response.total }
    });
  } else {
    dispatch({ type: GET_REVIEWS_FLR });
    // NotificationManager.error(response?.status?.description);
  }
};

export const clearReviews = () => async dispatch => {
  dispatch({ type: CLEAR_REVIEWS });
};

/**
|--------------------------------------------------
| REDUCERS
|--------------------------------------------------
*/
const INIT_STATE = {
  tenants: {
    data: [],
    loading: false
  },
  accTypes: {
    data: [],
    loading: false
  },
  amenities: {
    data: [],
    loading: false
  },
  rules: {
    data: [],
    loading: false
  },
  reviews: {
    data: [],
    loading: false
  }
};

export default function reducer(state = INIT_STATE, action = {}) {
  switch (action.type) {
    case GET_ALL_TENANTS_REQ:
      return {
        ...state,
        tenants: {
          data: [],
          total: state.items?.total,
          loading: true
        }
      };
    case GET_ALL_TENANTS_SCS:
      return {
        ...state,
        tenants: {
          data: action.payload?.data,
          total: action.payload?.total,
          loading: false
        }
      };
    case GET_ALL_TENANTS_FLR:
      return {
        ...state,
        tenants: {
          data: [],
          loading: false
        }
      };
    case GET_TENANT_FOR_SINGLE_VIEW_REQ:
      return {
        ...state,
        tenant: {
          data: "",
          loading: true
        }
      };
    case GET_TENANT_FOR_SINGLE_VIEW_SCS:
      return {
        ...state,
        tenant: {
          ...action.payload,
          loading: false
        }
      };
    case GET_TENANT_FOR_SINGLE_VIEW_FLR:
      return {
        ...state,
        tenant: {
          data: "",
          loading: false
        }
      };
    case GET_ACC_TYPES_REQ:
      return {
        ...state,
        accTypes: {
          data: [],
          loading: true
        }
      };
    case GET_ACC_TYPES_SCS:
      return {
        ...state,
        accTypes: {
          ...action.payload,
          loading: false
        }
      };
    case GET_ACC_TYPES_FLR:
      return {
        ...state,
        accTypes: {
          ...state.accTypes.data,
          loading: false
        }
      };
    case GET_AMENITIES_REQ:
      return {
        ...state,
        amenities: {
          data: [],
          loading: true
        }
      };
    case GET_AMENITIES_SCS:
      return {
        ...state,
        amenities: {
          ...action.payload,
          loading: false
        }
      };
    case GET_AMENITIES_FLR:
      return {
        ...state,
        amenities: {
          ...state.amenities.data,
          loading: false
        }
      };
    case GET_RULES_REQ:
      return {
        ...state,
        rules: {
          data: [],
          loading: true
        }
      };
    case GET_RULES_SCS:
      return {
        ...state,
        rules: {
          ...action.payload,
          loading: false
        }
      };
    case GET_RULES_FLR:
      return {
        ...state,
        rules: {
          ...state.rules.data,
          loading: false
        }
      };

    case GET_REVIEWS_REQ:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          loading: true
        }
      };
    case GET_REVIEWS_SCS:
      return {
        ...state,
        reviews: {
          data: state.reviews?.data?.concat(action.payload.data),
          total: action.payload.total,
          loading: false
        }
      };
    case GET_REVIEWS_FLR:
      return {
        ...state,
        reviews: {
          ...state.reviews.data,
          loading: false
        }
      };
    case CLEAR_REVIEWS:
      return {
        ...state,
        reviews: {
          data: [],
          loading: false
        }
      };

    default:
      return state;
  }
}
