/* eslint-disable no-nested-ternary */
// React, Redux, Router, Axios
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Slide from "@material-ui/core/Slide";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import axios from "axios";

// MUI
import { AppBar, Toolbar, Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
/* import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"; */

// Atoms
import Logo from "Components/atoms/UI/Logo";
import Button from "Components/atoms/buttons/Button";

// Molecules
import ChangeLanguage from "Components/molecules/ChangeLanguage";

const style = makeStyles(theme => ({
  title: {
    flexGrow: 1
  },
  root: {
    position: "fixed",
    bottom: theme.spacing(1),
    right: theme.spacing(1)
  },
  loadingBar: {
    zIndex: 9999,
    height: "7px",
    position: "fixed",
    top: 0,
    overflow: "hidden",
    width: "100%"
  }
}));

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

/* function ScrollTop(props) {
  const { children, window } = props;
  const classes = style();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100
  });

  const handleClick = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
} */

const Header = props => {
  const location = useLocation();
  const classes = style();

  const [loading, setLoading] = useState(false);

  const { position } = props;

  useEffect(() => {
    // Loading bar axios interceptors
    axios.interceptors.request.use(
      config => {
        setLoading(true);
        return config;
      },
      error => {
        setLoading(false);
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      response => {
        setLoading(false);
        return response;
      },
      error => {
        setLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <>
      <div id="back-to-top-anchor" />
      {loading && (
        <LinearProgress color="secondary" className={classes.loadingBar} />
      )}
      <HideOnScroll {...props}>
        <AppBar
          position={position}
          style={{ backgroundColor: "#ffffffe6", padding: "0.5rem 0" }}
        >
          <Container fixed maxWidth="xl">
            <Toolbar disableGutters>
              <Box className={classes.title}>
                <Logo width="145px" goTo="/" />
              </Box>
              <Box>
                <ChangeLanguage color="secondary" />
              </Box>
              <Box ml={1}>
                {location.pathname === "/register" ? (
                  <Button
                    size="small"
                    label="Already have an account"
                    color="secondary"
                    goTo="haveAccount"
                    variant="outlined"
                  />
                ) : location.pathname === "/customization" ? null : (
                  <Button
                    size="small"
                    label="Register"
                    color="primary"
                    goTo="register"
                    variant="outlined"
                  />
                )}
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>

      {/* <ScrollTop {...props}>
        <Fab
          style={{ zIndex: 99999 }}
          color="secondary"
          size="small"
          aria-label="scroll back to top"
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop> */}
    </>
  );
};

Header.defaultProps = {
  position: "fixed"
};

export default Header;
