import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import icon from "Assets/images/icon.png";
import App from "./App";

ReactDOM.render(
  <>
    <Helmet>
      <link rel="apple-touch-icon" href={icon} />
    </Helmet>
    <App />
  </>,
  document.getElementById("root")
);
