module.exports = {
  // HEADER
  Register: "Registriraj objekt",
  "Already have an account": "Imate račun?",
  "Just type in the email you registered to tripleHost and we will send you your login information.":
    "Samo upišite e-poštu koju ste registrirali na tripleHost i mi ćemo vam poslati podatke za prijavu.",

  // ACTIONS
  Back: "Natrag",
  Next: "Sljedeće",
  Finish: "Završi",
  Resend: "Pošalji",
  Create: "Kreiraj",
  Remove: "Ukloni",
  Search: "Pretraži",
  Confirm: "Potvrdi",
  "Add new": "Dodaj novi",
  Save: "Spremi",
  Accept: "Prihvati",
  Cancel: "Odustani",
  Close: "Zatvori",

  // INPUTS
  "E-mail address": "E-mail adresa",
  "Check in": "Dolazak",
  "Check out": "Odlazak",
  "No. of guests": "Br. gostiju",
  "First name": "Ime",
  "Last name": "Prezime",
  Email: "Email",

  // DRAG 'N' DROP
  drop_here_label: "Povuci i ispusti ili klikni ovdje za upload",
  "Remove image by clicking on it or": "Ukloni sliku klikom na nju ili",
  "Remove All": "Ukloni sve",

  // AUTOCOMPLETE
  "Where to": "Gdje želite putovati?",
  "No options": "Nema podataka",

  // REGISTRATION
  "Create an account to list and manage your property.":
    "Stvorite račun za upravljanje i promociju vašeg objekta.",
  "I agree that data can be used by tripleHost staff to contact me regarding tripleHost business offers and support.":
    "Kreiranjem računa slažem se da tripleHost osoblje može koristiti podatke da me kontaktiraju u vezi s poslovnim ponudama i podrškom.",
  "Portal listing": "Mjesto na portalu",
  "Promote your property": "Promovirajte svoj objekt",
  "Admin panel": "Admin sučelje",
  "Manage your bussines": "Upravljajte svojim poslovanjem",
  "Guest app": "Aplikacija za goste",
  "Engage your guests": "Angažirajte svoje goste",
  "Confirm your email address": "Potvrdite svoju email adresu",
  check_link:
    "Poslali smo vezu za potvrdu na vaš e-mail. Da biste dovršili postupak prijave i nastavili prilagođavanje, kliknite vezu za potvrdu.",

  // CUSTOMIZATION
  "APP CUSTOMIZATION": "PRILAGODBA APLIKACIJE",
  "PROPERTY INFO": "INFORMACIJE O OBJEKTU",
  ACCOMODATION: "SMJEŠTAJ",
  "Application information": "Informacije o aplikaciji",
  "Basic information": "Osnovne informacije",
  Name: "Naziv",
  "Property name": "Naziv objekta",
  "Property rating (if it has one)": "Ocjena objekta (ako postoji)",
  "Property type": "Tip objekta",
  Country: "Država",
  step_one_info_1:
    "Zašto je ovaj korak važan? Odgovor je jako jednostavan - kreirate aplikaciju za digitalizaciju vašeg objekta. Imate jedan hotel? Nazovite aplikaciju po vašem hotelu. Imate lanac hotela i želite imati jedinstvenu aplikaciju za sve hotele? Na dobrom ste mjestu, nazovite vašu aplikaciju po nazivu vaše grupacije a već na sljedećem koraku dodajte svoj prvi hotel.",
  step_one_info_menu_1:
    "Kreiraj aplikaciju kako bi digitalizirao svoju ponudu. Imaš li restoran, bar ili konobu?  Nazovi je po svom property.",
  step_one_info_2:
    "Zašto država? Država je tu da vam pojednostavimo unos parametara kao što su vremenska zona, valuta i jezik koji će se koristiti na aplikaciji. Naravno, u naprednim postavkama sve možete pojedinačno mijenjati i dodavati neograničen broj jezika.",
  step_one_info_3:
    "Da bi aplikacija bila privlačna na uređajima vaših gostiju pobrinite se za dobre dimenzije logotipa i ikone te odaberite boje koje će se prikazivati u aplikaciji. Preporučena razlučivost logotipa je 320 x 70 piksela. Ikona treba biti u omjeru 1x1. Ako imate posebne želje za brendiranje aplikacije na raspolaganju smo vam info@triple-host.com",
  "Add your first property": "Dodaj svoj prvi objekt",
  "Property images": "Slike objekta",
  Location: "Lokacija",
  Amenities: "Sadržaji",
  "Set as featured": "Postavi kao istaknuto",
  "Check in from": "Prijava od",
  "Check in until": "Prijava do",
  "Check out from": "Odjava od",
  "Check out until": "Odjava do",
  Rules: "Pravila",
  property_info_1:
    "Prvo odaberite vrstu objekta, a zatim uvezite najbolje slike kako biste u potpunosti predstavili svoje objekt. Preporučujemo slike snimljene širim lećama kako bi ste prikazali veći dio vašeg objekta u jednoj slici. Slike je poželjno kompresirati i spremiti u .jpg.",
  property_info_2:
    "Prikvačite svoje točno mjesto kako bi vas gosti brže pronašli. Pretražite svoje mjesto i klikom na kartu bacit ćete pribadaču na željeno mjesto. Također odaberite sadržaje koje su vam na raspolaganju i postavite pravila za vaše objekte. Ova svojstva moći ćete promijeniti i kasnije.",
  Logo: "Logotip",
  "Logo (negative/white)": "Logotip (negativ)",
  Icon: "Ikona",
  "Primary color": "Primarna boja",
  "Secondary color": "Sekundarna boja",
  "Accommodation types": "Tipovi smještaja",
  Images: "Slike",
  "Size (sqm)": "Veličina (kvadratni metri)",
  Capacity: "Kapacitet",
  "Number of this type": "Količina ove vrste",
  customization_info_1:
    "Za dodavanje novog tipa smještaja kliknite gumb DODAJ NOVI. Možete ga dodati kasnije ako to ne želite učiniti odmah.",
  customization_info_2:
    "Kliknite ZAVRŠI da biste pregledali informacije o nekretnini i dovršili registraciju.",
  "COMING SOON": "USKORO DOLAZI",
  "App name": "Ime aplikacije",
  "Property name and type": "Ime objekta i tip",
  "Accomodation types": "Tipovi smještaja",
  "Review and complete": "Pregledaj i završi",
  review_desc:
    "Provjeri unesene informacije, ako je sve uredu klikni na PRIHVATI da bi dobili informacije za prijavu.",
  "Save acc. type": "Spremi tip sobe",

  // REGISTRATION
  "Congrats!": "Čestitamo!",
  "Your settup is completed": "Uspješno ste postavili aplikaciju",
  "Go to the admin panel": "Idi na administracijsko sučelje",
  "Check out the guest app": "Pogledaj aplikaciju za goste",
  "Activating account": "Aktiviranje računa... Molimo pričekajte.",
  "Resend token text":
    "Ovaj token je istekao. Ako niste završili registraciju, možete zatražiti novi, samo upišite vašu e-mail adresu.",
  "Resend info text":
    "Izgleda da ste završili registraciju. Ako ste zaboravili pristupne podatke možemo vam poslati ih ponovno na Vaš e-mail.",
  "Checking your activation state": "Provjera stanja aktivacije... Pričekajte",

  // SELECT VIEW
  "Places found": "Mjesta pronađeno",
  "No properties in this place. Please try again with different place.":
    "Nema smještaja u ovoj pretrazi. Pokušajte s drugim mjestom.",
  "View your property on portal": "Pogledaj svoj objekt na portalu",

  // SINGLE VIEW
  "Check avalible rooms": "Provjeri dostupne sobe",
  "Book selected rooms": "Rezervirajte odabrane sobe",
  "Book this many rooms": "Rezerviraj ovoliko soba",
  "I want to book": "Želim rezervirati:",
  Total: "Ukupno: ",
  "Congratulations! You have succesfully placed your reservation.":
    "Čestitamo! Uspješno ste rezervirali.",
  "Check your email for further instructions.":
    "Dodatne upute potražite u svojoj e-pošti.",
  Reviews: "Recenzije/a",
  "No reviews": "Nema recenzija za ovaj objekt",
  "Not verified": "Nije verificirano",
  "Verification info":
    "Verifikacija obično traje 10 minuta, a ako imate problema, kontaktirajte nas na info@triple-host.com",

  // A2HS
  "To install this webapp on your device; tap":
    "Za instaliranje ove web aplikacije; pritisnite",
  "and then Add to homescreen.": "pa onda Dodaj na početni zaslon.",

  // MENU
  "Digital menu information": "Informacije o meniju",
  RegisterMenu: "Registriraj se",
  "Create digital menu for your property": "Kreirajte digitalni cjenik",
  menuSignupTitle: "1. Prijavi se",
  menuSignupText:
    "Unesite svoje osnovne podatke, kao što su naziv vašeg objekta, logotip i boje kako bi najbolje predstavljali vašu marku.",
  menuEnterTitle: "2. Unesi proizvode",
  menuEnterText:
    "Unesite proizvode i usluge koje nudite. Dodajte slike, cijenu i opis.",
  menuReadyTitle: "3. Digitalni cjenik je spreman",
  menuTextTitle:
    "Vaš digitalni jelovnik je spreman. Možete ga početi nuditi svojim gostima.",

  // MENU PAYMENT
  PayTitle: "Detalji o plaćanju",
  PaySubtitle:
    "Da biste dovršili registraciju, odaberite paket plaćanja i dodajte podatke o plaćanju",
  "I agree to terms": "Prihvatam uvjete",
  tosCheckText1: "Potvrdom okvira u nastavku pristaješ na naše",
  tosCheckText2: "uvjete upotrebe.",
  tosCheckText3:
    " TripleHost će automatski produljiti tvoje članstvo i naplatiti mjesečnu naknadu za njega (trenutačno ",
  tosCheckText4:
    ") putem odabranog načina plaćanja dok ne otkažeš uslugu. Uslugu možeš otkazati u bilo kojem trenutku kako bi izbjegao/la buduće naplate.",

  WSInfo:
    "TripleS d. o. o. koristi WSPay za online plaćanja. WSPay je siguran sustav za online plaćanje, plaćanje u realnom vremenu, kreditnim i debitnim karticama te drugim načinima plaćanja. WSPay kupcu i trgovcu osiguravaju siguran upis i prijenos upisanih podataka o karticama što podvrđuje i PCI DSS certifikat koji WSPay ima. WSPay koristi SSL certifikat 256 bitne enkripcije te TLS 1.2 kriptografski protokol kao najviše stupnjeve zaštite kod upisa i prijenosa podataka.",
  "ONLINE CARD PAYMENT": "ONLINE KARTIČNO PLAĆANJE",

  // BACKEND STRINGS
  "Time is not valid": "Vrijeme nije valjano",
  "You must provide Tenant": "Morate osigurati stanara",
  "Admin doesn't exists": "Administrator ne postoji",
  "Gallery item doesn't exist": "Stavka u galeriji ne postoji",
  "List item doesn't exist": "Stavka popisa ne postoji",
  "Data is not valid": "Datum nije valjan",
  "Date is in past": "Datum je u prošlosti",
  "Scheduled date is in past": "Zakazani datum je u prošlosti",
  "Email didn't send because it's empty": "E-pošta nije poslana jer je prazna",
  "You must provide organisation": "Morate osigurati organizaciju",
  "You must provide calendar": "Morate dostaviti kalendar",
  "Service must be of type calendar": "Usluga mora biti tipa kalendara",
  "This date is already added": "Ovaj je datum već dodan",
  "This calendar is already taken": "Ovaj je kalendar već zauzet",
  "This calendar is not working today": "Ovaj kalendar danas ne radi",
  "This date has reservations": "Ovaj datum ima rezerve",
  "You must provide working day": "Morate osigurati radni dan",
  "Working day already exists for this organisation":
    "Za ovu organizaciju već postoji radni dan",
  "User organisations doesn't exist": "Organizacije korisnika ne postoje",
  "Working hours doesn't exist": "Radno vrijeme ne postoji",
  "Organisation did not start working": "Organizacija nije počela raditi",
  "Working hours of the organization are over":
    "Radno vrijeme organizacije je završeno",
  "User doesn't exist": "Korisnik ne postoji",
  "Housing type doesn't exist": "Vrsta kućišta ne postoji",
  "Housing type amenity doesn't exist": "Pogodnost tipa stanovanja ne postoji",
  "Room is not active": "Soba nije aktivna",
  "Credit card doesn't exist": "Kreditna kartica ne postoji",
  "More than 7 days passed since your reservation expired":
    "Prošlo je više od 7 dana od isteka rezervacije",
  "Review doesn't exist": "Recenzija ne postoji",
  "Review type doesn't exist": "Vrsta recenzije ne postoji",
  "Review already exists": "Pregled već postoji",
  "Credit card already exists": "Kreditna kartica već postoji",
  "You have to deactivate corresponding number of rooms first":
    "Prvo morate deaktivirati odgovarajući broj soba",
  "Campaign doesn't exist": "Kampanja ne postoji",
  "Review type is not valid": "Vrsta recenzije nije važeća",
  "You have exceeded total number of rooms for this billing plan":
    "Premašili ste ukupan broj soba za ovaj plan naplate",
  "The billing plan has expired": "Plan naplate je istekao",
  "Transaction is not valid": "Transakcija nije važeća",
  "Billing doesn't exist": "Naplata ne postoji",
  "Token is invalid": "Žeton nije važeći",
  "Description length over 100 characters": "Duljina opisa preko 100 znakova",
  "Subscription doesn't exist": "Pretplata ne postoji",
  "This Tenant already exist": "Ovaj stanar već postoji",
  "Property type is not valid": "Vrsta svojstva nije važeća",
  "Country is not valid": "Država nije važeća",
  "Currency is not valid": "Valuta nije važeća",
  "Language is not valid": "Jezik nije valjan",
  "Duration is not valid": "Trajanje nije važeće",
  "Plan is not valid": "Plan nije valjan.",
  "This email already exist": "Ova e-pošta već postoji",
  "This name already exist": "Ovo ime već postoji",
  "Invalid step": "Nevažeći korak",
  "You have unfinished reservations for this calendar":
    "Imate nedovršene rezervacije za ovaj kalendar",
  "This title already exist": "Ovaj naslov već postoji",
  "This room number already exist": "Ovaj broj sobe već postoji",
  "This domain already exist": "Ova domena već postoji",
  "This admin domain already exist": "Ova administratorska domena već postoji",
  "Not a valid subscription": "Nije važeća pretplata",
  "Order doesn't exist": "Red ne postoji",
  "Order item doesn't exist": "Stavka narudžbe ne postoji",
  "Unavailable item/s. Please remove it from the cart":
    "Nedostupne stavke. Uklonite ga iz košarice",
  "Unavailable item": "Nedostupna stavka",
  "Service doesn't exist": "Usluga ne postoji",
  "Service is not activated": "Usluga nije aktivirana",
  "Service is not available": "Usluga nije dostupna",
  "Message is over 250 characters": "Poruka sadrži više od 250 znakova",
  "Filename is empty": "Naziv datoteke je prazan",
  "Filename format is not allowed": "Format naziva datoteke nije dopušten",
  "Image is not provided": "Slika nije navedena",
  "Visibility doesn't exist": "Vidljivost ne postoji",
  "Reservation type doesn't exist": "Vrsta rezervacije ne postoji",
  "Reservation doesn't exist": "Rezervacija ne postoji",
  "3DS Transaction Redirect": "3DS preusmjeravanje transakcija",
  "Reservation is not active": "Rezervacija nije aktivna",
  "Tenant is not activated": "Stanar nije aktiviran",
  "Tenant is already activated": "Stanar je već aktiviran",
  "Organisation is already activated": "Organizacija je već aktivirana",
  "Tenant is already verified": "Stanar je već potvrđen",
  "Tenant is no verified": "Stanar nije provjeren",
  "Tenant is already registered": "Stanar je već registriran",
  "Extras category doesn't exist": "Kategorija dodataka ne postoji",
  "Extras category doesn't activated": "Kategorija dodataka nije aktivirana",
  "Gallery doesn't exist": "Galerija ne postoji",
  "Price can't be negative": "Cijena ne može biti negativna",
  "Date from is greater than date to": "Datum od je veći od datuma do",
  "You have not entered a date until": "Tek ste unijeli datum",
  "Date to must be greater than todays date":
    "Datum mora biti veći od današnjeg datuma",
  "Opening time is greater than closing time":
    "Vrijeme otvaranja je duže od vremena zatvaranja",
  "Working time from is greater than working time to":
    "Radno vrijeme od je veće od radnog vremena do",
  "Working day is not in range of 0 - 6": "Radni dan nije u rasponu od 0 - 6",
  "Guest already exists": "Gost s danim identitetom već postoji",
  "Room doesn't exist": "Soba ne postoji",
  "Room already exists": "Soba s danim brojem već postoji",
  "Room is already taken": "Soba je već zauzeta",
  "Guest doesn't exist": "Gost ne postoji",
  "Guest with given reservation doesn't exist":
    "Gost s danom rezervacijom ne postoji",
  "Something went wrong": "Nešto je pošlo po zlu",
  "No panel connection": "Nema veze s pločom",
  "Access denied": "Pristup odbijen",
  "You can't change limit":
    "Ne možete promijeniti ograničenje jer niste vlasnik",
  "You have successfully accessed the route": "Uspješno ste pristupili ruti",
  "Successfully inserted": "Uspješno umetnuto",
  "Passwords do not match": "Lozinka ne postoji",
  "Payment succesfuly aproved. Check your mail for invoice":
    "Uplata je uspješno odobrena. Potražite fakturu u svojoj pošti",
  "Successfully updated": "Uloga je uspješno ažurirana",
  "Successfully processed": "Uspješno obrađeno",
  "Connection refuse": "Odbijanje veze",
  "This reservation has not started yet": "Ova rezervacija još nije započela",
  "This reservation has expired": "Ova rezervacija je istekla",
  "Please select at least one role": "Odaberite barem jednu listu",
  "Please select at least one organisation":
    "Odaberite barem jednu organizaciju",
  "Please select at least one date": "Odaberite barem jednu listu",
  "Please select at least one language": "Odaberite barem jedan jezik",
  "Please select at least one accommodation type":
    "Odaberite barem jednu vrstu smještaja",
  "Not a valid boolean": "Nije valjana logička vrijednost",
  "This featured already exist": "Ovo istaknuto već postoji",
  "This tag already exist": "Ova oznaka već postoji",
  "This tagging already exist": "Ovo označavanje već postoji",
  "This tag does not exist": "Ova oznaka ne postoji",
  "Notification status doesn't exist": "Status obavijesti ne postoji",
  "Notification doesn't exist": "Obavijest ne postoji",
  "This tagging does not exist": "Ovo označavanje ne postoji",
  "This organisation does not exist": "Ova organizacija ne postoji",
  "Organisation type doesn't exist": "Vrsta organizacije ne postoji",
  "Organisation type is not active": "Vrsta organizacije nije aktivna",
  "This accommodation type does not exist": "Ova vrsta smještaja ne postoji",
  "This amenity does not exist": "Ova ugodnost ne postoji",
  "Organisation is not activated": "Organizacija nije aktivirana",
  "Organisation is not available": "Organizacija nije dostupna",
  "Category does not exist": "Kategorija ne postoji",
  "Category is not active": "Kategorija nije aktivna",
  "Sub category does not exist": "Potkategorija ne postoji",
  "Sub category is not active": "Potkategorija nije aktivna",
  "Accomodation type does not exist": "Vrsta smještaja ne postoji",
  "Successfully deleted": "Jedinica je uspješno izbrisana.",
  "Language already exist": "Jezik već postoji",
  "Language doesn't exist": "Jezik ne postoji",
  "Accommodation type is not activated": "Vrsta smještaja nije aktivirana",
  "Accommodation unit doesn't exist": "Smještajna jedinica ne postoji",
  "Accommodation unit is not activated": "Smještajna jedinica nije aktivirana",
  "Home screen row doesn't exist": "Redak početnog zaslona ne postoji",
  "Home screen row is not activated": "Redak početnog zaslona nije aktiviran",
  "No price list is ined for this type":
    "Za ovu vrstu nije uvršten niti jedan cjenik",
  "No price is specified for one or more selected accommodation types in this time interval":
    "U ovom vremenskom intervalu nije navedena cijena za jednu ili više odabranih vrsta smještaja",
  "For this type of accommodation in the specified time interval there are one or more days with an unined price":
    "Za ovu vrstu smještaja u navedenom vremenskom intervalu postoji jedan ili više dana s jedinstvenom cijenom",
  "Accommodation has already been assigned": "Smještaj je već dodijeljen",
  "Accommodation has not been assigned, or accommodation type for this reservation does not exist":
    "Smještaj nije dodijeljen ili vrsta smještaja za ovu rezervaciju ne postoji",
  "For this reservation accommodation type does not exist":
    "Za ovu rezervaciju vrsta smještaja ne postoji",
  "You are not placed in a room": "Niste smješteni u sobi",
  "This guest is not assigned": "Ovaj gost nije dodijeljen",
  "This accommodation unit is not available at this time":
    "Ova smještajna jedinica trenutno nije dostupna",
  "Accommodation unit type does not match the booked type":
    "Tip smještajne jedinice ne odgovara rezerviranom tipu",
  "The accommodation cannot be updated because it is a reservation from the portal":
    "Smještaj se ne može ažurirati jer se radi o rezervaciji s portala",
  "You are no longer a guest, please contact the administrator":
    "Više niste gost, kontaktirajte administratora",
  "Guest does not exist, please contact the administrator":
    "Gost ne postoji, kontaktirajte administratora",
  "Incorrect room key": "Neispravan ključ sobe",
  "Type of accommodation no accommodation units created":
    "Vrsta smještaja nije kreirana",
  "Type of accommodation there are no available accommodation units":
    "Vrsta smještaja nema raspoloživih smještajnih jedinica",
  "This accommodation type does not exist, or there are no available  accommodation units":
    "Ova vrsta smještaja ne postoji ili nema raspoloživih smještajnih jedinica",
  "Accommodation type does not have enough accommodation units available":
    "Vrsta smještaja nema na raspolaganju dovoljno smještajnih jedinica",
  "You must enter the limit amount": "Morate unijeti iznos limita",
  "You have exceeded the limit": "Prekoračili ste ograničenje",
  "The order is already in this state": "Narudžba je već u ovom stanju",
  "You cannot manage a finished order":
    "Ne možete upravljati gotovom narudžbom",
  "One or more side dishes cannot be ordered":
    "Ne može se naručiti jedan ili više priloga",
  "You have not selected a date from": "Niste odabrali datum iz",
  "You have not selected a scheduled time": "Niste odabrali zakazano vrijeme",
  "You have not selected a date from or date to":
    "Niste odabrali datum ili datum do",
  "This template does not exists": "Ovaj predložak ne postoji",
  "Field is required": "Polje je obavezno",
  "Price can not be negative": "Cijena ne može biti negativna",
  "Value can not be negative": "Vrijednost ne može biti negativna",
  "Field must be between 2 and 100 characters long":
    "Polje mora imati između 2 i 100 znakova",
  "Field must be between 1 and 100 characters long":
    "Polje mora imati između 1 i 100 znakova",
  "Field must be between 6 and 128 characters long":
    "Polje mora imati između 6 i 128 znakova",
  "Number must be in range of 1-20": "Broj mora biti u rasponu od 1-20",
  "Value must be greater than 0": "Vrijednost mora biti veća od 0",
  "Email is not in the correct format": "E-pošta nije u ispravnom formatu",
  "Email is to long": "E-pošta je duga",
  "Stars must be in range of 1-5": "Zvijezde moraju biti u rasponu od 1-5",
  "Amount can not be negative": "Iznos ne može biti negativan"
};
