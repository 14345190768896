// React
import React from "react";

// Material-UI
import Box from "@material-ui/core/Box";

// Layout components
import Header from "Layouts/sections/Header";

// A2HS Tutorial
import A2HS from "Components/molecules/A2HS";

const TripleLayout = state => (
  <>
    <Header />
    <A2HS />
    <Box mt={10}>{state.children}</Box>
  </>
);

export default TripleLayout;
